import * as React from "react";
import Box from "@mui/material/Box";
import map from "../images/mcp-app.png";
import bonhomme from "../pictos/mcp-man.gif";
import news from "../images/newsletter.png";
import news2 from "../images/newsletter2.png";
import { useState } from "react";
import {Grid, Typography} from "@mui/material";
import qrcode from "../images/QRCode-test.png";

export default function Map() {
  const [bonhommePosition, setBonhommePosition] = useState({ x: 0, y: 0 });
  const [isCursorInside, setIsCursorInside] = useState(false);

  const handleMouseMove = (e) => {
    const boundingBox = e.currentTarget.getBoundingClientRect();
    const x = e.clientX - boundingBox.left;
    const y = e.clientY - boundingBox.top;
    const minX = 60;
    const minY = 50;
    const maxX = 230;
    const maxY = 440;

    // Mettre à jour les coordonnées du bonhomme en fonction de la position du curseur
    setBonhommePosition({
      x: Math.max(minX, Math.min(maxX, x)),
      y: Math.max(minY, Math.min(maxY, y)),
    });
  };

  return (
      <Grid
          sx={{
              padding: "4%",
              maxWidth: "xl",
              margin: "auto"
          }}
          id="map"
      >
          <Typography
              variant="h4"
              sx={{fontFamily: "PinkSunset-Regular", fontStyle: {color: "#01796F"}, maxWidth:{xs:"100%", md:"100%"},
                  paddingBottom:{xs:"5%", md:"3.5%", xl:"2%"},
                  textAlign: {xs:"center", md:"center"}, fontWeight: "regular"}}
          >
              Vérifies si l'application est disponible dans ta ville
          </Typography>
          <Grid
              container
              justifyContent="center"
              alignItems="top"
              spacing={3}
          >
              <Grid item xs={12} sm={6} justifyContent="center">
                  <Box sx={{display: "absolute", justifyContent: "center", marginLeft: "10%", marginRight: "10%", maxWidth:"600px"}}>
                      <Box
                          component="section"
                          sx={{
                              height: "auto",
                              backgroundColor: "#FFFFF",
                              position: "relative",
                              cursor: "none"
                          }}
                          onMouseMove={handleMouseMove}
                          onMouseEnter={() => setIsCursorInside(true)}
                          onMouseLeave={() => setIsCursorInside(false)}
                      >
                          <img src={map} style={{ width: "100%", margin: "auto", zIndex: "10"}} alt={"Carte de la France"}/>
                          {isCursorInside && (
                              <img
                                  src={bonhomme}
                                  alt="Bonhomme avançant sur la carte"
                                  style={{
                                      position: "absolute",
                                      left: bonhommePosition.x - 50,
                                      top: bonhommePosition.y - 80,
                                      width: "120px",
                                      height: "auto"
                                  }}
                              />
                          )}
                      </Box>
                  </Box>
              </Grid>
              <Grid
                  item
                  xs={12}
                  sm={6}
                  container
                  justifyContent="center"
                  alignItems="center"
              >
                  <Grid sx={{marginLeft: "10px", textAlign: {xs: "center", md: "left"}}}>
                      <Grid alignItems={'center'} justifyContent={"center"} textAlign="left">
                          <Typography
                              sx={{fontFamily: "Outfit, sans-serif", margin: 2, textAlign: "center"}}>
                              MCP - Mon commerçant de proximité démarre sa <b style={{color: "#e9d6a8"}}>phase de test</b>, l'application sera <b
                              style={{color: "#3fb498"}}>ouverte au
                              public en fin 2025.</b>
                          </Typography>
                          <Typography
                              sx={{fontFamily: "Outfit, sans-serif", margin: 2, marginTop: 5, textAlign: "center"}}>
                              Si tu veux <b style={{color: "#2d8376"}}>rejoindre la phase de test</b> de l'application, utilise le <b style={{color: "#3fb498"}}>QR
                              code ou clique dessus</b> pour accéder au <b style={{color: "#e9d6a8"}}>formulaire d'inscription :</b>
                          </Typography>
                          <Typography
                              sx={{fontFamily: "Outfit, sans-serif", margin: 2, marginTop: 5, textAlign: "center"}}>
                              <a href="https://forms.office.com/e/v3RrQwPMum" rel="noreferrer noopener">
                                  <img
                                      style={{width: "40%", borderRadius: 15}} src={qrcode}
                                      alt={"qrcode pour phase de test"}/>
                              </a>
                          </Typography>

                          <Typography
                              sx={{fontFamily: "Outfit, sans-serif", margin: 2, marginTop: 5, textAlign: "center"}}>
                          En attendant, n'hésites pas à te connecter sur <a href="https://linktr.ee/mcp.app" rel="noreferrer noopener">
                              <b style={{color: "#3fb498"}}>nos réseaux sociaux</b></a> et à suivre notre actualité, nous te réservons de belles surprises !
                          </Typography>
                          <Typography
                              sx={{
                                  fontFamily: "Outfit, sans-serif",
                                  margin: 2,
                                  textAlign: "center",
                                  textContent: "center",
                                  alignItems: "center",
                                  justifyContent: "space-evenly",
                                  display: "flex"
                              }}>
                              <a href="https://moncommercantdeproximite.fr/subscribe" rel="noreferrer noopener">
                                  <img
                                      src={news2} alt={"newsletter"}
                                      style={{maxWidth: 100}}>
                                  </img>
                              </a>
                              <a href="https://moncommercantdeproximite.fr/subscribe" rel="noreferrer noopener">
                                  <b style={{color: "#e9d6a8"}}>Tu n'es pas branché réseaux sociaux ? </b>
                                  <br></br>
                                  <b>Notre newsletter est là !</b>
                              </a>
                              <a href="https://moncommercantdeproximite.fr/subscribe" rel="noreferrer noopener">
                                  <img
                                      src={news} alt={"newsletter"}
                                      style={{maxWidth: 90}}>
                                  </img>
                              </a>
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </Grid>
  );
}
